/* Main container for the date picker component. */
.react-datepicker {
  background-color: #fff;
  border: 1px #00000030 solid;
  border-radius: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

/* Triangle shape used for pointing towards the selected date. */
.react-datepicker__triangle {
  visibility: hidden;
}

/* Header section containing navigation buttons, month, and year. */
.react-datepicker__header {
  background-color: #fff;
  border: none;
}

/* Styles for the current month text in the header. */
.react-datepicker__current-month {
  font-family: "Roboto";
  font-size: 14px;
  color: #00000090;
  font-weight: 600;
}

/* Styles for the navigation buttons (previous and next). */
.react-datepicker__navigation {
}

/* Styles for the previous and next navigation buttons, respectively. */
.react-datepicker__navigation--previous {
}
.react-datepicker__navigation--next {
}

/* Container for the month dropdown. */
.react-datepicker__month-dropdown-container {
}

/* Styles for the month dropdown. */
.react-datepicker__month-dropdown {
}

/* Container for the year dropdown. */
.react-datepicker__year-dropdown-container {
}

/* Styles for the year dropdown. */
.react-datepicker__year-dropdown {
}

/* Container for the months displayed in the calendar. */
.react-datepicker__month-container {
}

/* Styles for individual months in the calendar. */
.react-datepicker__month {
}

/* Styles for the container of week rows. */
.react-datepicker__week {
}

/* Styles for the container of day names (e.g., Sun, Mon, Tue, etc.). */
.react-datepicker__day-names {
}

/* Styles for individual day names. */
.react-datepicker__day-name {
  font-size: 13px;
  color: #00000050;
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
}

/* Styles for individual days in the calendar. */
.react-datepicker__day {
  font-size: 13px;
  color: #00000090;
  margin-left: 5px;
  margin-right: 5px;
}
.react-datepicker__day:hover {
  border-radius: 24px;
  background-color: #00000010;
}

/* Styles for today's date. */
.react-datepicker__day--today {
  background-color: transparent;
  font-weight: bolder;
  color: #000;
}
.react-datepicker__day--today:hover {
  border-radius: 24px;
  background-color: #00000010;
}

/* Styles for the selected date(s). */
.react-datepicker__day--selected {
  border-radius: 24px;
  background-color: #41b19931;
}

/* Styles for disabled days. */
.react-datepicker__day--disabled {
}

/* Styles for the date selected via keyboard navigation. */
.react-datepicker__day--keyboard-selected {
}

/* Styles for week numbers (if enabled). */
.react-datepicker__week-number {
}

/* Styles for the month when selecting a range of dates. */
.react-datepicker__month--selecting-range {
}

/* Styles for days in the selected date range. */
.react-datepicker__day--in-range {
  border-radius: 24px;
  background-color: #41b19931;
}
.react-datepicker__day--in-range:hover {
  border-radius: 24px;
  background-color: #41b19995;
}

/* Styles for the start date of a range. */
.react-datepicker__day--range-start {
  border-radius: 24px;
  background-color: #196fa8;
  color: #fff;
}
.react-datepicker__day--range-start:hover {
  background-color: #41b19995;
}

/* Styles for the end date of a range. */
.react-datepicker__day--range-end {
  border-radius: 24px;
  background-color: #196fa8;
  color: #fff;
}
.react-datepicker__day--range-end:hover {
  background-color: #41b19995;
}

/* Styles for days when selecting a range. */
.react-datepicker__day--in-selecting-range {
  border-radius: 24px;
  background-color: #41b19931;
}
.react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--range-start
  ):not(.react-datepicker__day--range-end) {
  border-radius: 24px;
  background-color: #41b19931;
}

/* Styles for disabled days. */
.react-datepicker__day--disabled {
}

/* Styles for highlighted days. */
.react-datepicker__day--highlighted {
}

/* Styles for custom highlighted days. */
.react-datepicker__day--highlighted-custom-1 {
}
.react-datepicker__day--highlighted-custom-2 {
}
.react-datepicker__day--highlighted-custom-3 {
}
.react-datepicker__day--highlighted-custom-4 {
}
.react-datepicker__day--highlighted-custom-5 {
}

/* Styles for the "Today" button. */
.react-datepicker__today-button {
}

/* Styles for the footer section of the date picker. */
.react-datepicker__footer {
}
