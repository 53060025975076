.chart-container canvas {
  height: 280px; /* Adjust height as needed */
  width: 280px; /* Adjust width as needed */
}

.selected {
  background-color: #41b19931;
}
.selected:hover {
  background-color: #41b19995 !important;
  border-radius: 0 !important;
}

.unselected {
  border-radius: 0 !important;
}
.unselected:hover {
  border-radius: 0 !important;
  background-color: #b6bcc330 !important;
}
